import React from 'react';
import './Self.scss';
import { motion } from 'framer-motion';

import { AppWrap } from '../../wrapper';

import { images } from '../../constants';

import Typewriter from 'typewriter-effect';

const scaleVariants = {
  whileInView: {
    scale: [0, 1],
    opacity: [0, 1],
    transition: {
      duration: 1,
      ease: 'easeInOut',
    },
  },
};

const Self = () => {
  return (
    <div>
      <div className='self__main'>
        <div className='self__main-profile'>
          <img className='profile-img' src={images.profile} alt='profile_bg' />
        </div>

        <div className='self__main-text'>
          <p className='p-text'>Hello, I am</p>
          <h1 className='name-text'>Nelman Ranasinghe</h1>
          <p className='p-text'>
            I am SeviceNow NextGen Graduate, Frontend Developer and Designer
            with hands-on experience in ServiceNow implementation, HTML, CSS,
            SASS, JS and a track record of creating responsive, device-agnostic
            interfaces via progressive enhancement. Also I have sound knowledge
            of UX and UI design., with specific focus on user stories to syemic
            design and rapid prototypes. In my job carrier I worked as a Senior
            Creative Director in leading advertising company.
          </p>

          <div className='typewriter'>
            <Typewriter
              onInit={(typewriter) => {
                typewriter
                  .typeString('Web Designer')
                  .pauseFor(1800)
                  .deleteAll()
                  .typeString('Frontend Developer')
                  .deleteAll()
                  .typeString('ServiceNow Developer')
                  .deleteAll()
                  .typeString('ServiceNow Developer/ Frontend Developer')
                  .start();
              }}
            />
          </div>

          <motion.div
            variants={scaleVariants}
            whileInView={scaleVariants.whileInView}
            className='app__header-circles'
          >
            {[
              images.html,
              images.css,
              images.sass,
              images.react,
              images.javascript,
              images.git,
            ].map((circle, index) => (
              <div className='circle-cmp app__flex' key={`circle-${index}`}>
                <img src={circle} alt='profile_bg' />
              </div>
            ))}
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default AppWrap(Self, 'home');
