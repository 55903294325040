import React from 'react';
// import { BsLinkedin} from 'react-icons/bs';
//import { FaFacebookF, FaLinkedin } from 'react-icons/fa';

function SocialMedia() {
  return (
    <div className='app__social'>
        {/* <div className="">
            <BsLinkedin />
        </div> */}
       
    </div>
    
    
  )
}

export default SocialMedia