import React from 'react';
import { motion } from 'framer-motion';

import { AppWrap } from '../../wrapper';

import { images } from '../../constants';

import './Header.scss';

const scaleVariants = {
  whileInView: {
    scale: [0, 1],
    opacity: [0, 1],
    transition: {
      duration: 1,
      ease: 'easeInOut',
    },
  },
};

const Header = () => {
  return (
    <div id='Home' className='app__header app__flex'>
      <motion.div
        whileInView={{ x: [-100, 0], opacity: [0, 1] }}
        transition={{ duration: 0.5 }}
        className='app__header-info'
      >
        <div className='app__header-badge'>
          <div style={{ marginLeft: 0 }}>
            <p className='p-text'>Hello, I am</p>
            <h1 className='name-text'>Nelman Ranasinghe</h1>

            <p className='p-text'>
              I am a ServiceNow Developer, Frontend Developer with hands-on
              experience in HTML, CSS, SASS and JS and has a track record of
              creating responsive, device-agnostic interfaces via progressive
              enhancement. Also, I have a sound knowledge of UX and UI design,
              focussing from user stories to systemic design and rapid
              prototypes. From my early career life, I also have a background
              from being a Senior Creative Director in a leading advertising
              agency.
            </p>
          </div>
        </div>
      </motion.div>

      <motion.div
        whileInView={{ opacity: [0, 1] }}
        transition={{ duration: 0.5, delayChildren: 0.5 }}
        className='app__header-img'
      >
        <img src={images.profile} alt='profile_bg' />

        {/* <motion.img
          whileInView={{ scale: [0, 1.1] }}
          transition={{ duration: 0.5, ease: 'easeInOut' }}
          src={images.circle}
          alt="profile_circle"
          className="overlay_circle"
        /> */}
      </motion.div>

      <motion.div
        variants={scaleVariants}
        whileInView={scaleVariants.whileInView}
        className='app__header-circles'
      >
        {[images.html, images.sass, images.react].map((circle, index) => (
          <div className='circle-cmp app__flex' key={`circle-${index}`}>
            <img src={circle} alt='profile_bg' />
          </div>
        ))}
      </motion.div>
    </div>
  );
};

export default AppWrap(Header, 'home');
